import AuthService from "@/shared/services/AuthService";

import type {
  ISocketCallback,
  ISocketClient,
  ICategory,
  INewCategory,
  IRecord,
  IRole,
  IUserItem,
} from "@/sockets/SocketClients.types";
import type {
  IChatRoom,
  INewChatRoom,
  INewMessage,
  ILastSeenMessage,
} from "@/sockets/types/Messages.types";

const BASE_URL = `wss://${
  location.hostname
}/wsconnect/${AuthService.getUserId()}/${AuthService.getMedAuthToken()}`;
console.log(`Trying to connect to :${BASE_URL}`);
const DEV_MODE = process.env.NODE_ENV !== "production";

let socketClientInstance: unknown;

const webSocketFactory = (baseUrl: string, mock = false) => {
  // To transfer the system to real data from the server,
  // please change the condition below: (!mock) to (mock).
  if (mock) {
    return new WebSocket(baseUrl);
  }
};

class WebSocketProxy {
  protected readonly socket: Promise<WebSocket>;

  protected routes: Map<string, (...args: unknown[]) => void> = new Map();

  constructor(baseUrl: string) {
    const conInfoString = localStorage.getItem("connectionInfo") || "{}";
    const connectionInfo = JSON.parse(conInfoString);
    if (connectionInfo.reloads == undefined) {
      connectionInfo.reloads = 0;
      localStorage.setItem("connectionInfo", JSON.stringify(connectionInfo));
    }

    if (!WebSocketProxy.instance) {
      WebSocketProxy.instance = this;

      this.socket = new Promise((resolve, reject) => {
        const ws = webSocketFactory(baseUrl, true);

        ws!.onopen = () => {
          if (ws!.readyState === 1) {
            if (connectionInfo.reloads != 0) {
              connectionInfo.reloads = 0;
              localStorage.setItem("connectionInfo", JSON.stringify(connectionInfo));
            }
            resolve(ws!);
          } else {
            reject(new Error(`readyState: ${ws!.readyState}`));
          }
        };

        ws!.onerror = (ev) => {
          console.log(`WebSocket Error: ${ev}`);
          console.log(ev);

          setTimeout(() => {
            window.location.replace(`https://${location.hostname}/login`);
          }, 500);
        };

        ws!.onmessage = (ev) => {
          const data = JSON.parse(ev.data);

          const cb = this.routes.get(data.apiRoute);

          if (DEV_MODE) {
            console.debug("routes.keys", this.routes.keys());
          }

          if (cb) {
            cb(data.response);
          }
        };
      });
    }

    this.socket = WebSocketProxy.instance.socket;

    return WebSocketProxy.instance;
  }

  emit(userAuthId: string, userAuthKey: string, requestName: string, params?: unknown): void {
    this.socket.then((socket) => {
      if (socket.readyState !== 1) {
        console.log("WS Closed. Trying to reconnect...");
        const connectionInfo = JSON.parse(localStorage.getItem("connectionInfo") || "");
        if (connectionInfo.reloads != undefined && typeof connectionInfo.reloads == "number") {
          if (connectionInfo.reloads < 4) {
            connectionInfo.reloads++;
            localStorage.setItem("connectionInfo", JSON.stringify(connectionInfo));
            location.reload();
            return;
          }
        }
        const win: Window = window;
        win.location = "/login";
      }

      socket.send(
        JSON.stringify({
          userAuthId,
          userAuthKey,
          request: requestName,
          params,
        })
      );
    });
  }

  on(apiRoute: string, cb: (response: any) => void): void {
    this.routes.set(apiRoute, cb);
  }

  destroy(apiRoute: string): void {
    this.routes.delete(apiRoute);
  }

  // Close Socket connection.
  async close(): Promise<void> {
    (await this.socket).close();
  }

  static create(baseUrl: string, newInstance = false): WebSocketProxy {
    if (newInstance) {
      WebSocketProxy.instance = undefined;
    }

    return new WebSocketProxy(baseUrl);
  }

  static instance?: WebSocketProxy;
}

export default class SocketClient {
  private readonly socket: WebSocketProxy;

  constructor(baseUrl: string) {
    // Singleton pattern.
    if (!socketClientInstance) {
      socketClientInstance = this;

      this.socket = WebSocketProxy.create(baseUrl);
    }

    const socketClientInstance1 = socketClientInstance as SocketClient;

    this.socket = socketClientInstance1.socket;

    return socketClientInstance1;
  }

  async keepalive(reconnect = true): Promise<void> {
    setInterval(() => {
      this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), "v1/ping");
    }, /* 10 seconds */ 10 * 1000);
  }

  /**
   * CreateUser.
   */

  createUser(userData: IUserItem) {
    const apiRoute = "v2/createUser";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, userData);
  }

  initCreateUserListener(cb: ISocketCallback) {
    const apiRoute = "v2/createUser";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetUserById.
   */

  getUser(): void {
    const apiRoute = "v1/getUser";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

  initGetUserListener(cb: ISocketCallback) {
    const apiRoute = "v1/getUser";
    this.socket.on(apiRoute, cb);
  }
  /**
   * GetPatientList.
   */
  getPatientList(): void {
    const apiRoute = "v1/getPatientList";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }

  initGetPatientListListener(cb: ISocketCallback) {
    const apiRoute = "v1/getPatientList";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetPatientById.
   */
  getPatientById(id: string): void {
    const apiRoute = "v1/getPatientById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, id);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  initGetPatientByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/getPatientById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * CreatePatient.
   */
  createPatient(patientData: any) {
    const apiRoute = "v1/createPatient";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, patientData);
  }
  initCreatePatientListener(cb: ISocketCallback) {
    const apiRoute = "v1/createPatient";
    this.socket.on(apiRoute, cb);
  }

  /**
   * UpdatePatient.
   */
  updatePatient(patientData: any) {
    const apiRoute = "v1/updatePatient";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, patientData);
  }
  initUpdatePatientListener(cb: ISocketCallback) {
    const apiRoute = "v1/updatePatient";
    this.socket.on(apiRoute, cb);
  }

  /**
   * Archive Patient
   */
  archivePatient(ptData: any) {
    const apiRoute = "v1/archivePatient";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, ptData);
  }

  /**
   * CreateRecordById.
   */
  createRecordById(patientData: any) {
    const apiRoute = "v1/createRecordById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, patientData);
  }

  initCreateRecordByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/createRecordById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * UpdateRecordById.
   */
  updateRecordById(recordData: any) {
    const apiRoute = "v1/updateRecordById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, recordData);
  }
  initUpdateRecordListener(cb: ISocketCallback) {
    const apiRoute = "v1/updateRecordById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetRecentRecord.
   */
  getRecentRecord(patientId: string) {
    const apiRoute = "v1/getRecentRecordById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, {
      patientId,
    });
  }

  initGetRecentRecordListener(cb: ISocketCallback) {
    const apiRoute = "v1/getRecentRecordById";
    this.socket.on(apiRoute, cb);
  }
  /**
   * CreateDraftById.
   */
  createDraftById(patientData: any) {
    const apiRoute = "v1/createDraftById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, patientData);
  }

  initCreateDraftByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/createDraftById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * Get Recent Draft
   */
  getDraftById(patientId: string) {
    const apiRoute = "v1/getDraftById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, {
      patientId,
    });
  }

  initGetDraftByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/getDraftById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetRecordById.

   */

  getRecordById(recordId: string) {
    const apiRoute = "v1/getRecordById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, {
      recordId,
      userAuthId: AuthService.getUserId(),
    });
  }

  initGetRecordByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/getRecordById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetAllRecordsById.
   */
  getRecordsById(patientId: string) {
    const apiRoute = "v1/getAllRecordsById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, {
      patientId,
    });
  }

  initGetRecordsListener(cb: ISocketCallback) {
    const apiRoute = "v1/getAllRecordsById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * CreateMedById.
   */
  createMedicationById(medicationData: any) {
    const apiRoute = "v1/createMedicationById";
    this.socket.emit(
      AuthService.getUserId(),
      AuthService.getMedAuthToken(),
      apiRoute,
      medicationData
    );
  }
  initCreateMedicationByIdListener(cb: ISocketCallback) {
    const apiRoute = "createMedicationById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * UpdateMedById.
   */
  updateMedicationById(medicationData: any) {
    const apiRoute = "v1/updateMedicationById";
    this.socket.emit(
      AuthService.getUserId(),
      AuthService.getMedAuthToken(),
      apiRoute,
      medicationData
    );
  }
  initUpdateMedicationByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/updateMedicationById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetMedsList.
   */
  getMedsList() {
    const apiRoute = "v1/getMedsList";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }

  initGetMedsListListener(cb: ISocketCallback) {
    const apiRoute = "v1/getMedsList";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetMedsById.
   */
  getMedsById(patientId: string) {
    const apiRoute = "v1/getPatientMedicationsById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, {
      patientId,
    });
  }

  initGetMedsListener(cb: ISocketCallback) {
    const apiRoute = "v1/getPatientMedicationsById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * UpdateMedsById.
   */
  updateMedById(medData: any) {
    const apiRoute = "v1/updateMedicationById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, medData);
  }

  initUpdateMedListener(cb: ISocketCallback) {
    const apiRoute = "v1/updateMedicationById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * deleteMedsById.
   */
  expireMedById(medData: any) {
    const apiRoute = "v1/expireMedById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, medData);
  }

  initDeleteMedListener(cb: ISocketCallback) {
    const apiRoute = "v1/deleteMedById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetFilesById.
   */
  getFilesByPatientId(patientId: string) {
    const apiRoute = "v1/getFilesByPatientId";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, {
      patientId,
    });
  }
  initGetFilesByPatientIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/getFilesByPatientId";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetLabsById.
   */
  getLabsById(patientId: string) {
    const apiRoute = "v1/getLabsById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, {
      patientId,
    });
  }
  initGetLabsListener(cb: ISocketCallback) {
    const apiRoute = "v1/getLabsById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetAppointmentList.
   */
  getAppointmentList(): void {
    const apiRoute = "v1/getAppointmentList";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }

  initGetAppointmentListListener(cb: ISocketCallback) {
    const apiRoute = "v1/getAppointmentList";
    this.socket.on(apiRoute, cb);
  }

  /**
   * UpdateAppointmentById.
   */
  updateAppointmentById(appointmentData: any): void {
    const apiRoute = "v1/updateAppointmentById";
    this.socket.emit(
      AuthService.getUserId(),
      AuthService.getMedAuthToken(),
      apiRoute,
      appointmentData
    );
  }

  initUpdateAppointmentByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/updateAppointmentById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetActivityList.
   */
  async getActivityList(): Promise<void> {
    const apiRoute = "v1/getAllPocRecords";
    await new Promise<void>((resolve) => {
      this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, () => {
        resolve();
      });
    });
  }
  initGetActivityListener(cb: ISocketCallback) {
    const apiRoute = "v1/getAllPocRecords";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetActivityByPatientId.
   */
  getActivityByPatientId(patientId: any): void {
    const apiRoute = "v1/getPocRecordsByPtId";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, patientId);
  }
  initGetActivityByPatientIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/getPocRecordsByPtId";
    this.socket.on(apiRoute, cb);
  }

  /**
   * DeleteAppointmentById.
   */
  deleteAppointmentById(id: any): void {
    const apiRoute = "v1/deleteAppointmentById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, id);
  }

  initDeleteAppointmentByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/deleteAppointmentById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetUserList.
   */
  getUserList(): void {
    const apiRoute = "v1/getUserList";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }
  initGetUserListListener(cb: ISocketCallback): void {
    const apiRoute = "v1/getUserList";
    this.socket.on(apiRoute, cb);
  }

  /**
   * updateUserById.
   */
  updateUserById(userData: any) {
    const apiRoute = "v1/updateUserById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, userData);
  }
  initUpdateUserByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/updateUserById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * Reset User Password
   */
  resetUserPass(userData: any) {
    const apiRoute = "v2/resetUserPass";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, userData);
  }

  /**
   * Archive User
   */
  archiveUser(userData: any) {
    const apiRoute = "v2/archiveUser";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, userData);
  }

  /**
   * GetRoleById.
   */
  getRoleById(userData: any): void {
    const apiRoute = "v1/getRoleById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, userData);
  }
  initGetRoleByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/getRoleById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetRolesList.
   */
  getRolesList(): void {
    const apiRoute = "v1/getRolesList";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }
  initGetRolesListListener(cb: ISocketCallback) {
    const apiRoute = "v1/getRolesList";
    this.socket.on(apiRoute, cb);
  }

  /**
   * CreateRole.
   */
  createRole(userData: IRole) {
    const apiRoute = "v1/createRole";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, userData);
  }
  initCreateRoleListener(cb: ISocketCallback) {
    const apiRoute = "v1/createRole";
    this.socket.on(apiRoute, cb);
  }

  /**
   * updateRoleById.
   */
  updateRoleById(userData: IRole) {
    const apiRoute = "v1/updateRoleById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, userData);
  }
  initUpdateRoleByIdListener(cb: ISocketCallback) {
    const apiRoute = "v1/updateRoleById";
    this.socket.on(apiRoute, cb);
  }

  /**
   * GetAccessClassList.
   */
  getAccessClassList(): void {
    const apiRoute = "v1/getAccessClassList";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }
  initGetAccessClassListListener(cb: ISocketCallback) {
    const apiRoute = "v1/getAccessClassList";
    this.socket.on(apiRoute, cb);
  }

  /**
   * Categories.
   */
  async getAllCategories(): Promise<void> {
    const apiRoute = "v1/getAllCategories";
    await this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }
  async initGetAllCategoriesListener(cb: ISocketCallback): Promise<void> {
    const apiRoute = "v1/getAllCategories";
    await this.socket.on(apiRoute, cb);
  }
  async createCategory(newCategory: INewCategory): Promise<void> {
    const apiRoute = "v1/createCategory";
    await this.socket.emit(
      AuthService.getUserId(),
      AuthService.getMedAuthToken(),
      apiRoute,
      newCategory
    );
  }
  async updateCategoryById(category: ICategory): Promise<void> {
    const apiRoute = "v1/updateCategoryById";
    await this.socket.emit(
      AuthService.getUserId(),
      AuthService.getMedAuthToken(),
      apiRoute,
      category
    );
  }

  async createChatRoom(newMessage: INewChatRoom): Promise<void> {
    const apiRoute = "v1/createChatRoom";
    await this.socket.emit(
      AuthService.getUserId(),
      AuthService.getMedAuthToken(),
      apiRoute,
      newMessage
    );
  }
  async deleteChatRoomById(id: IChatRoom): Promise<void> {
    const apiRoute = "v1/deleteChatRoomById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, id);
  }
  async updateChatRoomById(chatRoom: IChatRoom): Promise<void> {
    const apiRoute = "v1/updateChatRoomById";
    this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute, chatRoom);
  }
  async initGetAllChatRoomsListener(cb: ISocketCallback): Promise<void> {
    const apiRoute = "v1/getAllChatRooms";
    await this.socket.on(apiRoute, cb);
  }
  async getAllChatRooms(): Promise<void> {
    const apiRoute = "v1/getAllChatRooms";
    await this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }

  async initGetMyDestinationsListener(cb: ISocketCallback): Promise<void> {
    const apiRoute = "v1/getMyDestinations";
    await this.socket.on(apiRoute, cb);
  }
  async getMyDestinations(): Promise<void> {
    const apiRoute = "v1/getMyDestinations";
    await this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }

  async initGetMyChatMessagesListener(cb: ISocketCallback): Promise<void> {
    const apiRoute = "v1/getMyChatMessages";
    await this.socket.on(apiRoute, cb);
  }
  async getMyChatMessages(): Promise<void> {
    const apiRoute = "v1/getMyChatMessages";
    await this.socket.emit(AuthService.getUserId(), AuthService.getMedAuthToken(), apiRoute);
  }
  async createChatMessage(newMessage: INewMessage): Promise<void> {
    const apiRoute = "v1/createChatMessage";
    await this.socket.emit(
      AuthService.getUserId(),
      AuthService.getMedAuthToken(),
      apiRoute,
      newMessage
    );
  }
  async updateLastSeenMessage(lastMessage: ILastSeenMessage): Promise<void> {
    const apiRoute = "v1/updateLastSeenMessage";
    await this.socket.emit(
      AuthService.getUserId(),
      AuthService.getMedAuthToken(),
      apiRoute,
      lastMessage
    );
  }
  async initUpdateLastSeenMessageListener(cb: ISocketCallback): Promise<void> {
    const apiRoute = "v1/updateLastSeenMessage";
    await this.socket.on(apiRoute, cb);
  }

  static create(newInstance = false, baseUrl = BASE_URL): ISocketClient {
    if (newInstance) {
      socketClientInstance = undefined;
    }

    return new SocketClient(baseUrl);
  }

  // Close Socket connection.
  async close(): Promise<void> {
    await this.socket.close();
  }
}
